/* CSS */

.progress-container {
    display: flex;
    width: 100%;
    background-color: #ddd;
    border-radius: 8px;
    overflow: hidden;
  
  }
  
  .progress-bar {
    flex-grow: 1;
    height: 15px;
    border-radius: 8px;
    transition: width 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0px;
    width: 0;
  }

  .text-progress-bar {
    color: white;
    font-size: 10px;
  }