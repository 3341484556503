.alert-text {
    font-size: 18px;
}

.warning {
    margin-top: 0px;
}

.success, .error {
    margin-top: 10px;
    margin-bottom: 10px;
} 